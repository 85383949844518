
import VueDatepickerLocal from 'vue-datepicker-local'

export default {

	name: 'profiles',
	data() {
		return {
		    state1:'',
			profiles: [],
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getProfiles()
//		this.searchList()
	},
	components: {
		VueDatepickerLocal
	},
	methods: {
		getProfiles() {
			var _this = this;
			this.$http.post('/API/PLAT/profiles', {} , {
				headers: { "token": _this.token }
			}).then(function(response) {
				if(response.data.code == 200) {
					_this.profiles = response.data.data
					console.log(_this.profiles)
				}
			})
		},
		searchList() {
			var _this = this;
			_this.$http.post('/API/PLAT/findByPName', {
					pName: _this.state1
				}, {
					headers: {
						"token": _this.token
					}
				})
				.then(function(response) {
					if(response.data.code == 200) {
						_this.restaurants = response.data.data;
					}
				})
		},
		//搜索按钮
		search() {
			var _this = this;
			_this.$http.post('/API/PLAT/profileListPage', {
				page: _this.currentPage,
				pName: _this.state1
			}, {
				headers: {
					"token": _this.token
				}
			})
			.then(function(response) {
				if(response.data.code == 200) {
					//						console.log(response.data)
					_this.list = response.data.data.records;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.size;
					_this.currentPage = response.data.data.current;
				}
			})
		},
		//搜索框
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return(restaurant) => {
				return(restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//点击添加
		newHandel(msg) {
			this.$router.push({
				name: 'editProfile',
				params: {
					id: '0'
				}
			})
			this.$store.commit('newResult', msg)
		},
		//编辑
		Edit(msg, id) {
			//				this.newEdit=true;
			this.$router.push({
				name: 'addprofile',
				params: {
					id: id
				}
			})
			this.$store.commit('newResult', msg)
		},
		//删除信息
		delListItem() {
			console.log('删除')
		},
		//点击跳转详情
		gotoProfile(id) {
			this.$router.push({
				name: 'profile',
				params: {
					id: id
				}
			})
		},
	},

}
